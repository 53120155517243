import cn from 'classnames';
import styles from './AdsBlock.module.scss';
import { AdType } from '@/types';
import { AdsCard } from '../AdsCard';
import { useTranslations } from '@/hooks/useTranslations';

type AdsBlockProps = {
  ads: AdType[];
  className?: string;
};

export function AdsBlock({ ads, className }: AdsBlockProps) {
  const adsClassName = cn(styles.ads, className);
  const translations = useTranslations();

  const wrapperClassName = cn(styles.wrapper, {
    [styles.doubleHeight]: ads.length > 1,
  });

  if (!ads || ads.length === 0) return null;

  return (
    <div className={wrapperClassName}>
      <div className={adsClassName}>
        <p className={styles.heading}>{translations.ads}</p>
        <div className={styles.row}>
          {ads.map((ad) => (
            <AdsCard
              key={ad.id}
              title={ad.name}
              description={ad.text}
              img={ad.picture}
              href={ad.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
